// import { ContactForm } from "./components/contact-form/contact-form";
import { VideoPlayer } from "./components/video-player/video-player";

import '../scss/btf/btf.scss';
import { Gallery } from "./components/gallery/gallery";


document.addEventListener('DOMContentLoaded', function () {

	// if ( document.querySelector('.form-block') ) {
	// 	document.querySelectorAll('.form-block').forEach( (fb: HTMLElement) => {
	// 		new ContactForm(fb);
	// 	});
	// }

	if (document.querySelector('.video-player')) {
		const c = new VideoPlayer(document.querySelector('.video-player'));
	}


	if (document.querySelector('.gallery')) {
		const c = new Gallery( document.querySelector('.gallery'), document.querySelector('.masonry-grid') );
	}

}, false);

