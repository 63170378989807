
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import InfiniteScroll from 'infinite-scroll';

import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";


export class Gallery {

	wrapper: HTMLElement;
	gallery: HTMLElement;
	masonryItemWidth: number = 400;
	masonry: any;
	loadCount = 1;

	fbOptions: any;

	constructor( wrapper: HTMLElement, gallery: HTMLElement ) {
		this.wrapper = wrapper;
		this.gallery = gallery;
		this.fbOptions = {
			Toolbar: {
				display: {
					left: [],
					middle: [],
					right: ['download', 'close',],
				},
			}
		};

		// @ts-ignore
		InfiniteScroll.imagesLoaded = imagesLoaded;

		imagesLoaded( this.wrapper, ( instance ) => {
			this.getMasonryItemWidth();
			this.buildGallery();
			this.buildInfiniteScroll();
		});

		window.addEventListener('resize', () => {
			this.getMasonryItemWidth();
			this.buildGallery();
		});
	}

	getMasonryItemWidth() {
		if ( window.innerWidth < 480 ) {
			this.masonryItemWidth = window.innerWidth - 50;
		} else if ( window.innerWidth < 769 ) {
			this.masonryItemWidth = 317;
		} else if ( window.innerWidth < 1025 ) {
			this.masonryItemWidth = 279;
		} else if ( window.innerWidth < 1280 ) {
			this.masonryItemWidth = 295;
		}
	}

	buildGallery() {
		this.masonry = new Masonry( this.gallery, {
			itemSelector: '.masonry-item',
			gutter: 16
		});

		Fancybox.bind("[data-fancybox]", this.fbOptions);
	}



	buildInfiniteScroll() {
		let infScroll = new InfiniteScroll( this.gallery, {
			// options
			path: () => {
				return `/gallery-images/${(this.loadCount * 20)}`;
			},
			append: '.masonry-item',
			status: '.gallery-load-status',
			history: false,
			outlayer: this.masonry,
		});

		infScroll.on( 'load', ( body, path, response ) => {
			Fancybox.bind("[data-fancybox]", this.fbOptions);
		});

		infScroll.on( 'request', ( path, fetchPromise ) => {
			this.loadCount++;
		});
	}

}
