import './video-player.scss';


export class VideoPlayer {

	videoWrapper: HTMLElement;
	videoOverlay: HTMLElement;
	video: HTMLVideoElement;
	progress: number;


	constructor(el: HTMLElement) {
		this.videoWrapper = el;
		this.videoOverlay = this.videoWrapper.querySelector('.video-overlay');
		this.video        = this.videoWrapper.querySelector('video');
		this.progress     = 0;


		this.videoOverlay.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();

			if ( this.video.currentTime > 0 && !this.video.paused && !this.video.ended && this.video.readyState > 2 ) {
				this.pauseVideo();
			} else {
				this.playVideo();
			}
		});
	}

	playVideo() {
		this.videoWrapper.classList.add('playing');
		this.video.play();
	}

	pauseVideo() {
		this.videoWrapper.classList.remove('playing');
		this.video.pause();
	}


}